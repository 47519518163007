module.exports = [
  "lofi",
  "light", "dark",
  // {
  //   folkov: {
  //     primary: "#226F54",
  //     secondary: "#377CFB",
  //     accent: "#EA5234",
  //     neutral: "#191919",
  //     "base-100": "#FFFFFF",
  //     info: "#3ABFF8",
  //     success: "#36D399",
  //     warning: "#FBBD23",
  //     error: "#F87272",
  //   }
  // },
  // {
  //   sunday: {
  //     "primary": "#6BF178",
  //     "secondary": "#377CFB",
  //     "accent": "#37CDBE",
  //     "neutral": "#191919",
  //     "base-100": "#FFFFFF",
  //     "info": "#3ABFF8",
  //     "success": "#36D399",
  //     "warning": "#FBBD23",
  //     "error": "#F87272",
  //   }
  // },
  // 'acid', 'aqua', 'autumn', 'black', 'bumblebee', 'business', 'cmyk', 'coffee', 'corporate', 'cupcake', 'cyberpunk', 'dracula', 'emerald', 'fantasy', 'forest', 'garden', 'halloween', 'lemonade', 'lofi', 'luxury', 'night', 'pastel', 'retro', 'synthwave', 'valentine', 'winter', 'wireframe'
];