import { useState, useEffect, useMemo } from 'react';
import { TailwindTheme, ThemeChangerHook } from '../types';
import themes from '../../../../../tailwind-global-themes';

const THEME_NAMES = [ ...themes.map((t: TailwindTheme) => {
  let themeName = '';
  if (typeof t === 'string') {
    themeName = t;
  } else {
    themeName = Object.keys(t)[0];
  }
  return themeName;
}) ] as const;

type TailwindThemeName = typeof THEME_NAMES[number];

const saveThemeToLocalStorage = (themeName: string) => {
  window.localStorage.setItem('sb-react-daisyui-preview-theme', themeName);
};
const getSystemTheme = () => window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark': 'light';

export function useThemeChanger(theme = ''): ThemeChangerHook<TailwindThemeName> {
  const localTheme = window.localStorage.getItem('sb-react-daisyui-preview-theme');
  const [themeName, setThemeName] = useState(theme || localTheme || 'light');
  const [systemTheme, setSystemTheme] = useState(getSystemTheme());

  const themeState = useMemo(() => {
    return {themeName, systemTheme};
  }, [themeName, systemTheme]);

  const setTheme = (theme: TailwindThemeName) => {
    saveThemeToLocalStorage(theme);
    setThemeName(theme);
  };

  useEffect(() => {
    const themeChangeListener = (e: MediaQueryListEvent) => {
      const systemTheme = e.matches ? 'dark' : 'lite';
      setSystemTheme(systemTheme);
    };
    window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', themeChangeListener);
    return () => {
      window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').removeEventListener('change', themeChangeListener);
    };
  }, []);

  useEffect(() => {
    const themeName = themeState.themeName === 'System' ? themeState.systemTheme : themeState.themeName;
    document
      .getElementsByTagName('html')[0]
      .setAttribute('data-theme', themeName);
  }, [themeState]);

  return  [['System', ...THEME_NAMES], themeState.themeName, setTheme];
}

export default useThemeChanger;
