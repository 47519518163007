import { useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import BarndLogo from "../barnd-logo/barnd-logo";

type StoreInfo = {
  id?: string;
  reviewsFetched?: boolean;
  dontUse?: boolean;
};

type OnboardedApp = {
  id?: string;
  name: string;
  type?: string;
  appStore?: StoreInfo;
  googlePlayStore?: StoreInfo;
};

export interface AppOnboardProps {
  onboard: (appInfo: OnboardedApp) => void;
}

enum AppCategory {
  eCommerce = 'E-commerce',
  educational = 'Educational',
  finance = 'Finance',
  gaming = 'Gaming',
  lifestyle = 'Lifestyle',
  productivity = 'Productivity',
  socialMedia = 'Social media',
  None = ''
};

const CATEGORY_NAMES = Object.values(AppCategory).filter(v => v !== "");

type AppInfoFormFields = {
  name: string;
  type?: AppCategory;
};

type StoreConnectionFormFields = {
  appStore: StoreInfo;
  googlePlayStore: StoreInfo;
};

enum AppOnboardingSteps {
  AppInfo, StoreConnect
};


export function AppOnboard(props: AppOnboardProps) {
  const [currentStep, setStep] = useState<AppOnboardingSteps>(AppOnboardingSteps.AppInfo);
  const [appInfo, setAppInfo] = useState<AppInfoFormFields>({
    name: "",
    type: AppCategory.None
  });

  const isFirstStep = (step: AppOnboardingSteps) => step === AppOnboardingSteps.AppInfo;

  const { register: registerAppInfo, handleSubmit: handleAppInfoSubmit, formState: { errors: errorsAppInfo } } = useForm<AppInfoFormFields>({
    mode: 'all', shouldFocusError: true, reValidateMode: 'onChange'
  });
  const onAppNameSubmit: SubmitHandler<AppInfoFormFields> = (data: AppInfoFormFields) => {
    setAppInfo(data);
    setStep(AppOnboardingSteps.StoreConnect);
  };

  const { register: registerStoreConnect, handleSubmit: handleStoreConnectSubmit, formState: { errors: errorsStoreConnect }, getValues } = useForm<StoreConnectionFormFields>({
    mode: 'all', shouldFocusError: true, reValidateMode: 'onChange'
  });
  const onStoreConnectSubmit: SubmitHandler<StoreConnectionFormFields> = (data: StoreConnectionFormFields) => {
    const oa: OnboardedApp = {...appInfo, ...data};
    props.onboard(oa);
  };

  return (
    <div className="card flex-shrink-0 w-full max-w-sm shadow-lg mx-auto bg-base-100">
      <div className='card-body'>
        <BarndLogo className='self-start' alt='Folkov' href='/'/>
        <ul className="steps">
          <li className="step step-primary"></li>
          <li className={`step${!isFirstStep(currentStep) ? ' step-primary' : ''}`}></li>
        </ul>
        <h2 className='card-title'>
          {isFirstStep(currentStep) ? 'Set up your app' : 'Connect to app stores'}
        </h2>
        {isFirstStep(currentStep) && <p>Add logo and name of the app you want to connect</p>}
        {isFirstStep(currentStep) && <form onSubmit={handleAppInfoSubmit(onAppNameSubmit)}>
          <div className="form-control flex-row flex-wrap">
            <img className="mask mask-circle mr-5" width="46px" src="https://placeimg.com/160/160/arch" alt="App logo"/>
            <input type="text" placeholder="App name"
              className={`input flex-grow flex-shrink-0 input-bordered${errorsAppInfo.name?.message ? ' input-error' : ''}`}
              {...registerAppInfo('name', {
                required: 'Name is required',
              })}
            />
            <span className={`text-left min-w-full flex-grow flex-shrink-0 text-error mt-1 ${!errorsAppInfo.name?.message && 'pb-6'}`}>{errorsAppInfo.name?.message}</span>
          </div>
          {CATEGORY_NAMES.map(category => <div key={category} className="form-control">
              <label className="label cursor-pointer justify-start">
                <input type="radio" value={category}
                  className="radio"
                  {...registerAppInfo('type')}
                />
                <span className="label-text ml-8">{category}</span>
              </label>
            </div>)}
          <div className="card-actions justify-end">
            <button className="btn btn-primary" type="submit">Next</button>
          </div>
        </form>}
        {!isFirstStep(currentStep) && <form onSubmit={handleStoreConnectSubmit(onStoreConnectSubmit)}>
            <div className="form-control flex-row flex-wrap">
              <input type="text" placeholder="App Store ID"
                className={`input flex-grow input-bordered${errorsStoreConnect.appStore?.id?.message ? ' input-error' : ''}`}
                {...registerStoreConnect('appStore.id', {
                  required: !getValues().appStore?.dontUse,
                  validate: (value) => {
                    if (!getValues().appStore.dontUse && !value) return "ID is required";
                    else return;
                  }
                })}
              />
              <span className={`text-left min-w-full flex-grow flex-shrink-0 text-error mt-1 ${!errorsStoreConnect.appStore?.id?.message && 'pb-6'}`}>{errorsStoreConnect.appStore?.id?.message}</span>
            </div>
            <div className="form-control">
              <label className="label cursor-pointer justify-start">
                <input type="checkbox"
                  className="checkbox"
                  {...registerStoreConnect('appStore.dontUse', {
                    required: false
                  })}
                />
                <span className="label-text ml-8">I don't use AppStore</span>
              </label>
            </div>
            <div className="form-control flex-row flex-wrap">
              <input type="text" placeholder="App Store ID"
                className={`input flex-grow input-bordered${errorsStoreConnect.googlePlayStore?.id?.message ? ' input-error' : ''}`}
                {...registerStoreConnect('googlePlayStore.id', {
                  required: !getValues().googlePlayStore?.dontUse,
                  validate: (value) => {
                    if (!getValues().googlePlayStore.dontUse && !value) return "ID is required";
                    else return;
                  }
                })}
              />
              <span className={`text-left min-w-full flex-grow flex-shrink-0 text-error mt-1 ${!errorsStoreConnect.appStore?.id?.message && 'pb-6'}`}>{errorsStoreConnect.appStore?.id?.message}</span>
            </div>
            <div className="form-control">
              <label className="label cursor-pointer justify-start">
                <input type="checkbox"
                  className="checkbox"
                  {...registerStoreConnect('googlePlayStore.dontUse', {
                    required: false
                  })}
                />
                <span className="label-text ml-8">I don't use Google Play</span>
              </label>
            </div>
            <div className="card-actions justify-end">
              <button className="btn btn-outline"
                onClick={(e) => {
                  e.preventDefault();
                  setStep(AppOnboardingSteps.AppInfo);
                }}
              >Back</button>
              <button className="btn btn-primary" type="submit">Connect</button>
            </div>
          </form>}
      </div>
    </div>
  );
}

export default AppOnboard;
export type AppInfo = OnboardedApp;