import { useDebounce } from "@folkov/hooks";
import { SearchIcon } from "@heroicons/react/outline";
import { useEffect, useState } from "react";

export enum SearchBarVariants {
  sm = "sm",
  md = "md"
};

/* eslint-disable-next-line */
export interface SearchBarProps {
  variant?: SearchBarVariants;
  onSearch?: (query: string) => void;
  value: string;
}

export function SearchBar(props: SearchBarProps) {
  const {onSearch, value} = props;
  const [query, setQuery] = useState(value);

  const debouncedQuery = useDebounce(query, 500);

  useEffect(() => {
    onSearch?.call(null, debouncedQuery as string);
  }, [debouncedQuery, onSearch]);

  const searchBars = {
    sm: () => (<div className="form-control px-3 relative flex-1">
                <input type="text" placeholder="Search" className="input input-sm input-ghost pl-7"
                  value={query}
                  onChange={e => setQuery(e.target.value)} />
                <SearchIcon className="absolute pointer-events-none ml-1 mt-1 w-5 stroke-slate-400"/>
              </div>),
    md: () => (<div className="form-control px-3 relative flex-1">
                <input type="text" placeholder="Search" className="input input-md input-ghost pl-9"
                  value={query}
                  onChange={e => setQuery(e.target.value)} />
                <SearchIcon className="absolute pointer-events-none ml-2 mt-3 w-6 stroke-slate-400"/>
              </div>)
  };
  return searchBars[props.variant || SearchBarVariants.sm]();
}

export default SearchBar;
