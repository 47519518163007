import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { getAnalytics } from 'firebase/analytics';

import * as styles from './index.module.css';
import { firebaseConnector, firebaseAppCheck } from '@folkov/firebase-connector';
import { getUserStore } from '@folkov/user-store';
import { BarndLogo } from '@folkov/ui-components';
import { ReactComponent as Enlisted } from '../images/enlisted.svg';
import UserFrom from '../components/user-from';
import { SparklesIcon, DocumentSearchIcon, UserGroupIcon } from '@heroicons/react/outline';

export function Index() {
  /*
   * Replace the elements below with your own.
   *
   * Note: The corresponding styles are in the ./landing-page.css file.
   */
  const [enlisted, setEnlisted] = useState(false);
  const [userStore, setUserStore] = useState(null);

  const appCheckSiteKey = process.env.GATSBY_APP_CHECK_SITE_KEY;


  useEffect(() => {
    const { app } = firebaseConnector({
      apiKey: process.env.GATSBY_FIREBASE_API_KEY,
      appId: process.env.GATSBY_FIREBASE_APP_ID,
      authDomain: process.env.GATSBY_FIREBASE_AUTH_DOMAIN,
      databaseURL: process.env.GATSBY_FIREBASE_DATABASE_URL,
      measurementId: process.env.GATSBY_FIREBASE_MEASUREMENT_ID,
      messagingSenderId: process.env.GATSBY_FIREBASE_MESSAGE_SENDER_ID,
      projectId: process.env.GATSBY_FIREBASE_PROJECT_ID,
      storageBucket: process.env.GATSBY_FIREBASE_STORAGE_BUCKET
    });
    getAnalytics(app);
    firebaseAppCheck(app, appCheckSiteKey);
    const userStore = getUserStore(app);
    setUserStore(userStore);
  }, [appCheckSiteKey])


  const saveUser = (name: string, email: string) => {
    userStore.save(name, email)
      .then(() => setEnlisted(true))
      .catch(err => console.log(err));
  }

  const clickLearnMore = () => {
    window.scrollTo({ top: document.body.scrollHeight - 1500, behavior: 'smooth' });
  }

  return (
    <>
    <Helmet>
        <title>Folkov</title>
    </Helmet>
    <header className='max-x center py-16 pl-12'>
        <BarndLogo alt='Folkov' href='/'/>
    </header>
    <main className='max-x width-100 center bg-banner'>
        <section className={styles.content}>
            <h1 className='center'>You know more <br/> than you think</h1>
            <p className='center'>
              All your data and research in one&nbsp;
              <br className='break-s'/>
              collaborative customer dicovery platform.
            </p>
            <section className={`${styles.flexCenter} button-section`}>
                <button type='button' className={`${styles.learnMore} bg-black text-white`} onClick={clickLearnMore}>Learn More</button>
            </section>
            <section className="pt-px-info d-flex flex-col justify-center items-center w-max-info">
              <article className="d-flex flex-col justify-center items-center pb-2">
                <SparklesIcon className="w-12 h-12" width={"3rem"} height={"3rem"}/>
                <h2 className="text-3xl text-center semibold my-1">Speed up your research with intelligent insights.</h2>
                <p className="text-center lh-1">Folkov connects to the data sources where information about your customers are available. With the use of intelligent technology, it analyzes the data and provides you with insights in no time through intuitive dashboards and efficient search.</p>
              </article>
              <article className="d-flex flex-col justify-center items-center pb-2">
                <DocumentSearchIcon className="w-12 h-12" width={"3rem"} height={"3rem"}/>
                <h2 className="text-3xl text-center semibold my-1">Keep track of important changes.</h2>
                <p className="text-center lh-1">Track significant changes in your product performance and get insights on the cause of it.</p>
              </article>
              <article className="d-flex flex-col justify-center items-center">
                <UserGroupIcon className="w-12 h-12" width={"3rem"} height={"3rem"}/>
                <h2 className="text-3xl text-center semibold my-1">Make research your team sport.</h2>
                <p className="text-center lh-1">Document and manage all your discovery in Folkov pages. It is a way to easily share and collaborate with your team on your next innovation.</p>
              </article>
            </section>
            <section className="form-container center">
                <h1>Stay in the loop <br/> on what's coming soon</h1>
                <section className={`enlisted ${enlisted ? 'visible': ''}`}>
                    <section>
                        <Enlisted />
                        <span>You are on the list</span>
                    </section>
                </section>
                <UserFrom onSubmit={ saveUser } className={enlisted ? 'hidden': ''} />
            </section>
        </section>
    </main>
    <footer className='max-x center'>
        <span>© Folkov Technologies Inc. 2022</span>
    </footer>
    </>
  );
}

export default Index;
