import { Auth, AuthError, AuthProvider, CustomParameters, signInWithRedirect, UserCredential } from 'firebase/auth';
import { useCallback, useMemo, useState } from 'react';
import { SignInWithRedirectHook } from '../types';

export const useSignInWithRedirect = (
  auth: Auth,
  createProvider: (
    scopes?: string[],
    customOAuthParameters?: CustomParameters
  ) => AuthProvider
): SignInWithRedirectHook => {
  const [error, setError] = useState<AuthError>();
  const [loggedInUser, setLoggedInUser] = useState<UserCredential>();
  const [loading, setLoading] = useState<boolean>(false);

  const signInWithProvider = useCallback(async (
    scopes?: string[],
    customOAuthParameters?: CustomParameters
  ) => {
    setLoading(true);
    setError(undefined);
    try {
      const provider = createProvider(scopes, customOAuthParameters);
      const user = await signInWithRedirect(auth, provider);
      setLoggedInUser(user);
    } catch (err) {
      setError(err as AuthError);
    } finally {
      setLoading(false);
    }
  }, [auth, createProvider]);

  const resArray: SignInWithRedirectHook = useMemo(() => [
    signInWithProvider,
    loggedInUser,
    loading,
    error,
  ], [signInWithProvider,
    loggedInUser,
    loading,
    error]);
  return useMemo<SignInWithRedirectHook>(() => resArray, [resArray]);

}

export default useSignInWithRedirect;
