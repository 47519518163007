import { useForm, SubmitHandler } from "react-hook-form";
import React, { useState } from 'react';
import BarndLogo from '../barnd-logo/barnd-logo';
import { useAuthState } from 'react-firebase-hooks/auth';
import { ActionCodeSettings, Auth, sendSignInLinkToEmail, signOut } from "firebase/auth";
import { useSignInWithGoogle } from "@folkov/hooks";
import { useNavigate } from 'react-router-dom';

type AuthFormFields = {
  email: string,
};

export interface AuthFormProps {
  title: string;
  auth: Auth;
}

export function AuthForm(props: AuthFormProps) {
  const {auth} = props;
  const [emailLinkSentMessage, setEmailLinkSentMessage] = React.useState<string>();

  const [invalidUserEmail, setInvalidUserEmail] = useState(false);

  const actionCodeSettings: ActionCodeSettings = {
    url: window.location.href,
    handleCodeInApp: true,
  };

  const [user, loading] = useAuthState(auth);

  const navigate = useNavigate();
  React.useEffect(() => {
    if (loading) {
      return;
    }
    if (user) {
      if (!user.email || !user.email.toLowerCase().endsWith("@folkov.com")) {
        setInvalidUserEmail(true);
      } else {
        setEmailLinkSentMessage('');
        navigate("/");
      }
    }
  }, [user, loading, navigate]);

  const [signInWithGoogle] = useSignInWithGoogle(auth);

  const handelSendEmailLink = async (email: string) => {
    try {
      await sendSignInLinkToEmail(auth, email, actionCodeSettings);
      window.localStorage.setItem('emailForSignIn', email);
      setEmailLinkSentMessage(`Please check your email "${email}" for sign in link.`);
    } catch (error) {
      console.error(error);
    }
  };

  const continueWithGoogle = () => signInWithGoogle();

  const validateEmail = (email: string) => {
    if (email.toLowerCase().endsWith('@folkov.com')) return true;
    else return 'Folkov is not available at the moment.';
  };
  const { register, handleSubmit, formState: { errors } } = useForm<AuthFormFields>({
    mode: 'all', shouldFocusError: true, reValidateMode: 'onChange'
  });
  const onSubmit: SubmitHandler<AuthFormFields> = data => handelSendEmailLink(data.email);

  const handleContinueWithGoogle = (e: React.FormEvent) => {
    e.preventDefault();
    continueWithGoogle();
  };

  const handleSignout = () => {
    signOut(auth);
    navigate("/");
  };

  const result = (
    <div className="card flex-shrink-0 w-full max-w-sm shadow-lg mx-auto bg-base-100">
      <div className='card-body'>
        <BarndLogo className='self-start' alt='Folkov' href='/'/>
        <h1 className='self-start'>
          {props.title}
        </h1>
        {invalidUserEmail && <div className="form-control">
            <label className="label">
              <span className="label-text text-error">Folkov is not available to public at the moment.</span>
            </label>
            <button className="btn btn-outline btn-error" onClick={handleSignout}>Logout</button>
          </div>}
        {!invalidUserEmail && !emailLinkSentMessage && <>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="form-control">
              <label className="label">
                <span className="label-text">Email *</span>
              </label>
              <input type="text" placeholder="email"
                className={`input input-bordered${errors.email?.message ? ' input-error' : ''}`}
                {...register('email', {
                  required: 'Email is required',
                  validate: validateEmail,
                  pattern: {
                      value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                      message: 'Please enter a valid email',
                  },
                })}
              />
              <span className={`text-left text-error mt-1 ${!errors.email?.message && 'pb-6'}`}>{errors.email?.message}</span>
            </div>
            <div className="form-control">
              <button className="btn btn-primary" disabled={!!errors.email?.message}>Continue with Email</button>
            </div>
          </form>
          <div className='divider my-2'></div>
          <form onSubmit={handleContinueWithGoogle}>
            <div className="form-control">
              <button className="btn btn-outline">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"
                  style={{width: '20px', height: '20px', marginTop: '-5px', fill: 'inherit', flexShrink: 0, backfaceVisibility: 'hidden', marginRight: '8px'}}>
                  <g transform="matrix(1, 0, 0, 1, 27.009001, -39.238998)">
                    <path fill="#4285F4" d="M -3.264 51.509 C -3.264 50.719 -3.334 49.969 -3.454 49.239 L -14.754 49.239 L -14.754 53.749 L -8.284 53.749 C -8.574 55.229 -9.424 56.479 -10.684 57.329 L -10.684 60.329 L -6.824 60.329 C -4.564 58.239 -3.264 55.159 -3.264 51.509 Z"/>
                    <path fill="#34A853" d="M -14.754 63.239 C -11.514 63.239 -8.804 62.159 -6.824 60.329 L -10.684 57.329 C -11.764 58.049 -13.134 58.489 -14.754 58.489 C -17.884 58.489 -20.534 56.379 -21.484 53.529 L -25.464 53.529 L -25.464 56.619 C -23.494 60.539 -19.444 63.239 -14.754 63.239 Z"/>
                    <path fill="#FBBC05" d="M -21.484 53.529 C -21.734 52.809 -21.864 52.039 -21.864 51.239 C -21.864 50.439 -21.724 49.669 -21.484 48.949 L -21.484 45.859 L -25.464 45.859 C -26.284 47.479 -26.754 49.299 -26.754 51.239 C -26.754 53.179 -26.284 54.999 -25.464 56.619 L -21.484 53.529 Z"/>
                    <path fill="#EA4335" d="M -14.754 43.989 C -12.984 43.989 -11.404 44.599 -10.154 45.789 L -6.734 42.369 C -8.804 40.429 -11.514 39.239 -14.754 39.239 C -19.444 39.239 -23.494 41.939 -25.464 45.859 L -21.484 48.949 C -20.534 46.099 -17.884 43.989 -14.754 43.989 Z"/>
                  </g>
                </svg>
                Continue with Google
              </button>
            </div>
          </form>
        </>}
        {!invalidUserEmail && emailLinkSentMessage && <h3>{emailLinkSentMessage}</h3>}
      </div>
    </div>
  );

  return loading ? <progress className="progress w-full"></progress> : result;
}

export default AuthForm;
