import { CheckIcon, XIcon } from "@heroicons/react/outline";
import { ReactNode, useEffect, useState } from "react";

/* eslint-disable-next-line */
export interface EditOnFocusProps {
  className?: string;
  children?: ReactNode;
  value: string;
  onEdited: (value: string) => void;
}

export function EditOnFocus(props: EditOnFocusProps) {
  const {className, children, value, onEdited} = props;

  const [text, setText] = useState(value);

  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    if (!isEditing) setText(value);
  }, [isEditing, setText, value]);

  const handleTitleEdit = () => {
    onEdited(text)
    setIsEditing(false);
  };

  return isEditing ? <div className="input-group">
      <input type="text" value={text} className="input input-bordered"
        autoFocus
        onFocus={e => e.target.select()}
        onChange={e => setText(e.target.value)}
        onKeyDown={e => {
          if (e.key === "Enter") handleTitleEdit();
          if (e.key === "Escape") setIsEditing(false);
        }}
      />
      <button className="btn btn-square" onClick={handleTitleEdit}>
        <CheckIcon className="w-6 h-6" />
      </button>
      <button className="btn btn-square" onClick={() => setIsEditing(false)}>
        <XIcon className="w-6 h-6" />
      </button>
    </div> :
    children ? <label onClick={() => setIsEditing(true)}>{children}</label> :
    value === undefined ? <label onClick={() => setIsEditing(true)} className="text-error">No Value</label> :
    <label onClick={() => setIsEditing(true)} className={className}>{value}</label>;
}

export default EditOnFocus;
