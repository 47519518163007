import { FirebaseApp } from 'firebase/app';
import { child, getDatabase, push, ref, set, get } from 'firebase/database';

export const getUserStore = (app: FirebaseApp) => {
  const db = getDatabase(app);
  const path = 'users';
  return {
    save: async (name: string, email: string) => {
      const value = {name, email};
      const newUserKey = push(child(ref(db), path)).key;
      return await set(ref(db, `${path}/` + newUserKey), value);
    }
  };
};
