import { useMemo, useState } from "react";

export interface TextExpandProps {
  limit: number;
  children: string;
}

export function TextExpand(props: TextExpandProps) {
  const {limit, children} = props;
  const [showMore, setShowMore] = useState(false);
  const text = useMemo(() => {
    if (showMore) {
      return children;
    } else {
      return `${children.substring(0, limit)}${limit >= children.length ? "": "..."}`;
    }
  }, [showMore, children, limit]);
  return (
    <>
      <span>{text}</span>{limit < children.length && (showMore ?
        <><br/> <span className="link link-secondary text-xs text-opacity-50" onClick={() => setShowMore(false)}>show less</span> </>:
        <><br/> <span className="link link-secondary text-xs text-opacity-50" onClick={() => setShowMore(true)}>show more</span> </>)
        }
    </>
  );
}

export default TextExpand;
