import React from 'react';
import Logo from './folkov-logo.png';
import styled from 'styled-components';

const StyledAnchor = styled.a`
  font-size: 24px;
  text-decoration: none;
  white-space: nowrap;
  font-weight: 500;
  @media (max-width: 540px) {
    font-size: 18px;
  }
`;

const StyledImg = styled.img`
  vertical-align: text-bottom;
  width: 32px;
  height: 32px;
  margin-right: 12px;
  display: inline-block;
  @media (max-width: 540px) {
    width: 24px;
    height: 24px;
  }
`;

/* eslint-disable-next-line */
export interface BarndLogoProps {
  href: string,
  alt: string,
  width?: number,
  height?: number
  style?: any,
  className?: string,
};

export function BarndLogo(props: BarndLogoProps) {
  const {
    href,
    alt,
    style,
    className,
    ...imageAttribs
  } = props;

  return (
    <StyledAnchor href={href} style={style} className={className}>
      <StyledImg src={ Logo } alt={alt} {...imageAttribs} />
      {alt}
    </StyledAnchor>
  );
}

export default BarndLogo;
