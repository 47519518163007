import React, { useState } from 'react';
import BarndLogo from '../barnd-logo/barnd-logo';
import { useAuthState } from 'react-firebase-hooks/auth';
import { Auth, signInAnonymously } from "firebase/auth";
import { useDebounce } from "@folkov/hooks";
import { useNavigate } from 'react-router-dom';

export interface AuthYCDemoProps {
  title: string;
  auth: Auth;
}

export type DemoState = {
  demo: boolean;
};

const DEMO_CODE = process.env['NX_REACT_APP_YC_DEMO_CODE'] as string; // || "ycw2023folx";

export function AuthYCDemo(props: AuthYCDemoProps) {

  const {auth} = props;
  const [demoCode, setDemoCode] = useState("");
  const deferedDemoCode = useDebounce(demoCode, 600) as string;

  const [user, loading] = useAuthState(auth);

  const navigate = useNavigate();
  React.useEffect(() => {
    if (loading) {
      return;
    }
    if (user && deferedDemoCode === DEMO_CODE) {
      navigate("/", {state: {demo: true} as DemoState});
    }
  }, [user, loading, navigate, deferedDemoCode]);

  const anonymousSignIn = async () => {
    try {
      await signInAnonymously(auth);
    } catch (err) {
      console.log({err});
    }
  };

  const handleAnonymousSignIn = (e: React.FormEvent) => {
    e.preventDefault();
    anonymousSignIn();
  };

  const result = (
    <div className="card flex-shrink-0 w-full max-w-sm shadow-lg mx-auto bg-base-100">
      <div className='card-body'>
        <BarndLogo className='self-start' alt='Folkov' href='/'/>
        <h1 className='self-start'>
          {props.title}
        </h1>
        <div className="form-control w-full">
          <label className="label">
            <span className="label-text">Enter demo code</span>
          </label>
          <input type="text" className="input input-bordered w-full" value={demoCode}
            onChange={e => setDemoCode(e.target.value)}/>
        </div>
        <div className="form-control pt-4">
          <button className="btn btn-primary" disabled={deferedDemoCode !== DEMO_CODE}
            onClick={handleAnonymousSignIn}
          >Sign in</button>
        </div>
      </div>
    </div>
  );

  return loading ? <progress className="progress w-full"></progress> : result;
}

export default AuthYCDemo;
