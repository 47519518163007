import * as styles from './user-from.module.css';
import React from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';


/* eslint-disable-next-line */
export interface UserFromProps {
  onSubmit: (name: string, email: string) => void;
  className?: string;
}

export interface FormFields {
  name: string;
  email: string;
}

// const initialForm: FormFields = { name: '', email: '' };

export function UserFrom(props: UserFromProps) {

  const { register, handleSubmit, formState: { errors } } = useForm<FormFields>();
  const onSubmit: SubmitHandler<FormFields> = data => props.onSubmit(data.name, data.email);
  const nameErrorMessage = errors.name ? 'Name is required' : '';
  const emailErrorMessage = (() => {
    if (!errors.email) return '';
    if (errors.email.type === 'required') return 'Email is required';
    return 'Email is invalid';
  })();

  return (
    <form className={`${styles.registrationForm} ${props.className}`} onSubmit={(handleSubmit(onSubmit))} noValidate>
        <section className={styles.inputGroup}>
            <label htmlFor="name">Name</label>
            <input type="text" name="name" id="name"
              {...register('name', { required: true })}
              autoComplete="off"
              required
              className={nameErrorMessage ? styles.error : ''}
              title={nameErrorMessage ? errors.name.message : ''}
            />
            <label
              className={`${nameErrorMessage || styles.hidden} ${styles.error} ${styles.py0} ${styles.pt5}`}
              htmlFor="name"
            >
              {nameErrorMessage}
            </label>
        </section>
        <section className={styles.inputGroup}>
            <label htmlFor="email">Email</label>
            <input type="email" name="email" id="email"
              {...register('email', { required: true, pattern: /\S+@\S+\.\S+/ })}
              autoComplete="off"
              required
              className={emailErrorMessage ? styles.error : ''}
            />
            <label
              className={`${emailErrorMessage || styles.hidden} ${styles.error} ${styles.py0} ${styles.pt5}`}
              htmlFor="email"
            >
              {emailErrorMessage}
            </label>
        </section>
        <section className={styles.inputGroup}>
            <button type="submit"
              className={`${styles.formSubmitButton} bg-black text-white`}
            >
              Submit
            </button>
        </section>
    </form>
  );
}

export default UserFrom;
