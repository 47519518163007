import { useState, useEffect } from 'react';
import { UseDebounce } from '../types';

export function useDebounce(value: UseDebounce, delay: number): UseDebounce {
  const [debouncedValue, setDebouncedValue] = useState(value);
  useEffect(
    () => {
      const handler = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);
      return () => {
        clearTimeout(handler);
      };
    },
    [value, delay]
  );
  return debouncedValue;
}

export default useDebounce;
