import { Auth, CustomParameters, GoogleAuthProvider } from "firebase/auth";
import { SignInWithRedirectHook } from "../types";
import useSignInWithRedirect from "../use-sign-in-with-redirect/use-sign-in-with-redirect";

export const useSignInWithGoogle = (auth: Auth): SignInWithRedirectHook => {
  const createGoogleAuthProvider = (
    scopes?: string[],
    customOAuthParameters?: CustomParameters
  ) => {
    const provider = new GoogleAuthProvider();
    if (scopes) {
      scopes.forEach((scope) => provider.addScope(scope));
    }
    if (customOAuthParameters) {
      provider.setCustomParameters(customOAuthParameters);
    }
    return provider;
  };
  return useSignInWithRedirect(auth, createGoogleAuthProvider);
};